import React from 'react';
import Layout from '../components/Layout';
import ContactUsPage from '../components/ContactUsPage';
import useSiteMetadata from '../hooks/use-site-metadata';
import {graphql, useStaticQuery} from "gatsby";

const ContactUsTemplate = ({location}) => {
  const {subtitle: siteSubtitle} = useSiteMetadata();
  const {bg} = useStaticQuery(graphql`
      query {
          bg: allFile(filter: { sourceInstanceName: { eq: "preview" } name: { eq: "contact-us" } }) {
              nodes {
                  name
                  publicURL
              }
          }
      }
  `);
  return (
    <Layout title="CyberVision - Contact Us"
            hideBreadcrumbs
            location={location}
            description={siteSubtitle}
            socialImage={bg.nodes[0].publicURL}>
      <ContactUsPage/>
    </Layout>
  );
};

export default ContactUsTemplate
