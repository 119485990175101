import React, {useEffect} from 'react';
import Typography from '@material-ui/core/Typography';
import { graphql, useStaticQuery } from 'gatsby';
import Section from '../Section';
import Mail from '../../assets/images/mail.svg';
import styles from './ContactUsPage.module.scss';

const formId = 'contact-us-form';

const ContactUsPage = () => {
  const checkForm = () => {
    const formFrame = document.querySelector(`#${formId} iframe`);
    if (formFrame) {
      const messageField = formFrame.contentDocument.querySelector('textarea[name=message]');
      if (messageField) {
        messageField.textContent = sessionStorage.getItem('contactMessage');
        const inputEvent = new Event('input', {
          bubbles: true,
          cancelable: true,
        });

        messageField.dispatchEvent(inputEvent);
      } else {
        setTimeout(checkForm, 1000);
      }
    } else {
      setTimeout(checkForm, 1000);
    }
  };
  useEffect(() => {
    const script = document.createElement('script');
    const scriptText = document.createTextNode('window.hbspt && hbspt.forms.create({portalId: "8031920",formId: "a6b7ce15-513f-47f5-809e-5cba8652f681"})');
    script.appendChild(scriptText);
    const source = document.getElementById(formId);
    const defaultMessage = sessionStorage.getItem('contactMessage');
    if (defaultMessage) {
      checkForm();
    }
    source.appendChild(script);
    return () => sessionStorage.removeItem('contactMessage');
  }, []);
  const { socials } = useStaticQuery(graphql`
    query {
      socials: allFile(filter: { sourceInstanceName: { eq: "socialImages" } }) {
        nodes {
          name
          publicURL
        }
      }
    }
  `);
  const socialsMap = socials.nodes.reduce((acc, next) => {
    acc[next.name] = next.publicURL;
    return acc;
  }, {});
  return (
    <Section backgroundColor="#F7F8FB" height="auto">
      <div className={styles['contact-us__container']}>
        <Typography variant="h2" color="secondary" align="center">
          Contact us
        </Typography>
        <div>
          <div className={styles['text__container']}>
            <Typography variant="h3">
              Leave us a message and we'll get in touch with you soon
            </Typography>
            <Typography className={styles.subtitle}>E-mail</Typography>
            <Mail className={styles.mail}/>
            <Typography className={styles.subtitle}>Mailing address</Typography>
            <Typography className={styles.text}>
              CyberVision, Inc., <br /> 20801 Biscayne Blvd., Suite 403,
              <br /> Aventura, FL 33180
            </Typography>
            <Typography className={styles.subtitle}>Social Media</Typography>
            <div className={styles['social__container']}>
              <a
                  href="https://www.linkedin.com/company/cybervision-inc-/"
                  target="_blank"
                  rel="noreferrer noopener"
              >
                <img src={socialsMap['linkedin-contact-us']} alt="linkedin" />
              </a>
              <a
                href="https://www.facebook.com/cybervisionUSA"
                target="_blank"
                rel="noreferrer noopener"
              >
                <img src={socialsMap['facebook-contact-us']} alt="facebook" />
              </a>
              <a
                href="https://twitter.com/CyberVisionUSA"
                target="_blank"
                rel="noreferrer noopener"
              >
                <img src={socialsMap['twitter-contact-us']} alt="twitter" />
              </a>
            </div>
          </div>
          <div id={formId} className={styles['form']}/>
        </div>
      </div>
    </Section>
  );
};

export default ContactUsPage;
